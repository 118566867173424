<template>
  <div class="wrapper">
    <div class="page">
      <div id="main-loading-cover">
        <div class="page-inner" id="main_page">
          <div class="va-card mb-4">
            <div class="dash_card_body pad-lr-0 ov-h">
              <div class="header_section padd-30 pt-0">
                <div class="w-100 t-wrap">
                  <h4 class="menu_header">Rule Setup</h4>
                </div>
              </div>
              <hr>
              <div class="create_items_section ">
                <form @submit.prevent="createRule">
                  <div class="row py-3">
                    <div class="col-md-12" v-if="errors ">
                      <div class="alert alert-danger">
                        <ul>
                          <li v-for="(err_msg,errindex) in errorMsg" :key="errindex">{{err_msg}}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
  
                  <div class="row">
                    
                    <div class="col-lg-6 pad-lr-0">
                      <div class="pl-5 pr-4">
                    
                        <div class="setting_content">
                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5" for="dm_consumer_secret">Key(s):</label>
                              <div class="col-sm-7">
                                <div>
                                  <Multiselect v-model="keys" mode="tags" placeholder="Add Product as Key" track-by="name"
                                    @blur="v$.keys.$touch"
                                    label="name" :close-on-select="true" :searchable="true" :options="options">
                                    <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                      <div class="multiselect-tag is-user">
                                        {{ option.name }}
                                        <span v-if="!disabled" class="multiselect-tag-remove"
                                          @mousedown.prevent="handleTagRemove(option, $event)">
                                          <span class="multiselect-tag-remove-icon"></span>
                                        </span>
                                      </div>
                                    </template>
                                  </Multiselect>
                                </div>
                                <div v-if="keys.length == 1" class="mt-4">
                                  <strong><span>Edit key:</span></strong> <br>
                                  <input type="number" class="form-control" v-model="keys">
                                </div>
                                
                                <div class="input_error" v-if="v$.keys.$error">Key(s) is required.</div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5" for="email"> Points:</label>
                              <div class="col-sm-7">
                                <input type="number" class="form-control" v-model="points" @blur="v$.points.$touch">
                                <div class="input_error" v-if="v$.points.$error">Points is required.</div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5" for="email"> Status:</label>
                              <div class="col-sm-7">
                                <select class="form-control" id="" v-model="status" @blur="v$.status.$touch">
                                  <option value="1">Published</option>
                                  <option value="0">Unpublished</option>
                                </select>
                                <div class="input_error" v-if="v$.status.$error">Status is required.</div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5" for="email"> Based On:</label>
                              <div class="col-sm-7">
                                <select class="form-control" id="" v-model="basedOn"  @blur="v$.basedOn.$touch">
                                  <option value="quantity">Quantity</option>
                                  <option value="amount">Amount</option>
                                </select>
                                <div class="input_error" v-if="v$.basedOn.$error">Based on is required.</div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group setting_form_grp" >
                            <div class="row" v-if="basedOn == 'quantity'">
                              <label class="control-label col-sm-5" for="email">Quantity:</label>
                              <div class="col-sm-7">
                                <input type="number" class="form-control" v-model="quantity">
                              </div>
                            </div> 
                            <div class="row" v-else>
                              <label class="control-label col-sm-5" for="email"> Amount :</label>
                              <div class="col-sm-7">
                                <input type="number" class="form-control" v-model="amount">
                              </div>
                            </div>
                          </div>
                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5" for="email"> Description:</label>
                              <div class="col-sm-7">
                                <textarea class="form-control" rows="5" cols="600" v-model="description" @blur="v$.description.$touch"></textarea>
                                <div class="input_error" v-if="v$.description.$error">Description is required.</div>
                              </div>
                            </div>
                          </div>
                          <!--  -->
                      
                        
                        </div>
                      </div>
                    </div>
                  <!--  -->
                  </div>
                  <div class="row bt-b">
                    <div class="col-md-12">
                      <div class="form-group ml-2r mt-3">
                        <button type="submit"  class="btn btn-orange"><span v-if="spinner" v-html="searchspinner"></span> <span v-else>Save Changes</span></button>
                      </div>
                    </div>
                  </div>
                <!-- end row -->
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="main-loader"></div>
    </div>
  </div>
</template>
  
  <script>
  import Multiselect from '@vueform/multiselect';
  import useVuelidate from '@vuelidate/core';
  import { useToast } from "vue-toastification";
  import { required } from '@vuelidate/validators';
  import ReusableDataMixins from '@/mixins/ReusableDataMixins';
  const toast = useToast();
  import "tinymce/tinymce";
  import "tinymce/themes/silver";
  import "tinymce/icons/default";
  import "tinymce/skins/ui/oxide/skin.css";
  // import Editor from '@tinymce/tinymce-vue';
  export default {
     components: {
          // Editor
          Multiselect
      },
      setup() {
            return { v$: useVuelidate() }
      },
      data() {
          return {
              options: [],
              keys: [],
              points: 0,
              status: 1,
              description: '',
              basedOn: 'quantity',
              quantity:0,
              amount: 0,
              errors: false,
              errorMsg: [],
              spinner: false,
              searchspinner: "<i class='fa fa-spinner fa-spin '></i> Saving Changes..."
  
          }
      },
      mounted() {
          this.getItems();
      },
      validations() {
    return {
        keys: { required },
        points: { required },
        basedOn: { required },
        description: { required },
        status: { required }
        }
    },
      methods: {
          async getItems() {
            let url = `${this.baseUrl}/menu_item`;
            url = new URL(url);
            url.searchParams.set('merchant_id', this.merchantID);
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            this.axios.get(url, config).then((response) => {
                this.menuItems = response.data.data
                for(let x=0; x< this.menuItems.length; x++){
                  this.options.push({
                    "value": this.menuItems[x].metadata.pos_id,
                    "name":this.menuItems[x].menu_item.item_name
                  })

                }
            }).catch((error) => {
              if (error.response.status === 401) {
                            this.$router.push({
                                name: 'login'
                            });
                    }
            })
        },
        async createRule() {
            this.spinner = true;
            this.errors = [];
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = `${this.dashBaseUrl}/create-loyalty-rule`;
            const payload = {
                'keys': this.keys,
                'points': this.points,
                'status': this.status,
                'description': this.description,
                'based_on': this.basedOn,
                'merchant_id': this.merchantID,
                'quantity' : this.quantity,
                'amount': this.amount
            }
            this.axios.post(url,payload,config).then((response)=>{
                if(response.status ===200){
                    toast.success('settings changes was successfully updated');
                    this.$router.push({
                                name: 'loyalty_rules'
                    });
                }
            }).catch((error)=>{
                if (error.response.status === 400) {
                    this.errors = true;
                    this.errorMsg = error.response.data.errors
                }
                if (error.response.status === 401) {
                            this.$router.push({
                                name: 'login'
                            });
                    }
            }).finally(()=> this.spinner = false);

        }
      },
      mixins: [ReusableDataMixins]
  }
  </script>

